import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Index from './containers/Index';
import NotFound from '../app/containers/NotFound';
import AuthenticatedRoute from '../components/AuthenticatedRoute';

/**
 * Handles ReactJS Routing
 * @param {object} argv object defining properties to use in this function
 * @param {boolean} argv.hasAlerts whether or not the route has an alert
 * @returns {Route} react routing object
 */
export default function MyRoutes({ hasAlerts }) {
    return (
        <NavBar hasAlerts={hasAlerts}>
            <Routes>
                <Route path='/' element={
                    <AuthenticatedRoute>
                        <Index />
                    </AuthenticatedRoute>
                }
                />
                {/* Finally, catch all unmatched routes */}
                <Route path="*" element={
                    <NotFound />
                }
                />
            </Routes>
        </NavBar>
    );
}
